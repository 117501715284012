import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Container from '../components/elements/Container'

import styles from '../scss/pages/404.module.scss'

const NotFoundPage = () => (
  <Page className={styles.page}>
    <SEO title="404 - The Page can't be found" />

    <section className={styles.section}>
      <Container>
        <div className={styles.wrapper}>
          <h2 className={styles.whoops}>Whoops!</h2>
          <p className={styles.text}>
            We couldn't find the page you were looking for.
          </p>
          <Link to="/" className={cn('c-button', styles.link)}>
            back to home
          </Link>
        </div>
      </Container>
    </section>
  </Page>
)

export default NotFoundPage
